import { defineComponent, onMounted } from "vue";
export default defineComponent({
    name: "insights",
    props: {
        blocks: Object,
        widgetClasses: String
    },
    setup: function () {
        onMounted(function () {
            localStorage.setItem("currentPage", "1");
        });
    },
    beforeUnmount: function () {
        // this.blocks = null;
    },
    computed: {
        length: function () {
            var _a;
            return (_a = this.blocks) === null || _a === void 0 ? void 0 : _a.length;
        }
    },
    methods: {
        /**
         * Formats number with leading hashtag and zeros
         * @returns string
         */
        pad: function (num, size) {
            //fixed size
            var SIZE = size ? size : 9;
            // num = num.toString();
            num = "" + num;
            while (num.length < SIZE) {
                num = "0" + num;
            }
            return "#" + num;
        },
        /**
         * Calculates the time since block's creation
         * @returns string
         */
        calcElapsedTime: function (time) {
            //if it's a protection block
            if (time == null) {
                return "";
            }
            else {
                // data string to milisec
                var createdTime = Number(new Date(time)); // UTC time
                var nowUtc = Number(new Date(this.getCurrentTimeUTC())); // UTC time
                var diff = Number(nowUtc - createdTime);
                return this.msToTime(diff);
            }
        },
        /**
         * Gets the UTC time
         * @returns string
         */
        getCurrentTimeUTC: function () {
            //RETURN:
            //      = number of milliseconds between current UTC time and midnight of January 1, 1970
            var tmLoc = new Date();
            //The offset is in minutes -- convert it to ms
            return tmLoc.getTime() + tmLoc.getTimezoneOffset() * 60000;
        },
        /**
         * Formats miliseconds to hh:mm:ss
         * @returns string
         */
        msToTime: function (s) {
            // Pad to 2 or 3 digits, default is 2
            function pad(n, z) {
                z = z || 2;
                return ("00" + n).slice(-z);
            }
            var ms = s % 1000;
            s = (s - ms) / 1000;
            var secs = s % 60;
            s = (s - secs) / 60;
            var mins = s % 60;
            var hrs = (s - mins) / 60;
            // return pad(hrs) + ":" + pad(mins) + ":" + pad(secs) + "." + pad(ms, 3);
            if (hrs == 1) {
                return pad(hrs) + " hour ago";
            }
            else if (hrs != 0) {
                return pad(hrs) + " hours ago";
            }
            else {
                return pad(mins) + " minutes ago";
            }
        }
    }
});
