<template>
  <!--begin::Tables Widget 13-->
  <div :class="widgetClasses" class="card">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5 ">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label text-dark fw-bolder fs-3 mb-1"
          >Latest Blocks</span
        >

        <span class="text-muted mt-1 fw-bold fs-7"
          >The 10 most recent blocks</span
        >
      </h3>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3">
      <!--begin::Table container-->
      <div class="table-responsive" v-if="length > 0 && length <= 10">
        <!--begin::Table-->
        <table
          id="table"
          class="table table-dark table-striped table-hover table-sm table-row-bordered table-row-gray-100 align-middle gs-0 gy-3"
        >
          <!--begin::Table head-->
          <thead>
            <tr class="bg-dark fw-bolder text-white align-middle">
              <th class="min-w-50px fs-2 ps-5" data-field="incrNum">#</th>
              <th class="min-w-100px fs-2" data-field="id">BlockID</th>
              <th class="min-w-100px fs-2" data-field="age">Age</th>
              <th class="min-w-80px fs-2" data-field="hash">Hash</th>
              <th class="min-w-100px fs-2" data-field="type">Type</th>
              <th class="min-w-100px fs-2" data-field="status">Status</th>
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody>
            <template v-for="(item, index) in blocks" :key="index">
              <tr>
                <!--begin::Block number-->
                <td class="ps-5">
                  <span class=" text-muted fs-7">
                    {{ index + 1 }}
                  </span>
                </td>
                <!--end::Block number-->
                <!--begin::BlockID-->
                <!--begin::Normal BlockID -->
                <td v-if="item.type" class="ps-5">
                  <router-link
                    :to="{ name: 'blockInfo', params: { hash: item.checksum } }"
                    class="fw-bolder text-hover-primary fs-7"
                  >
                    {{ pad(item.id, 9) }}
                  </router-link>
                </td>
                <!--end::Normal BlockID -->
                <!--begin::Protection Block's BlockID -->
                <td class="ps-5" v-else>
                  <span class=" text-muted fs-7">
                    {{ pad(item.id, 9) }}
                  </span>
                </td>
                <!--end::Protection Block's BlockID -->
                <!--end::BlockID-->

                <!--begin::Age-->
                <td>
                  <span class="text-muted text-hover-primary d-block mb-1 fs-7">
                    {{ calcElapsedTime(item.created_at) }}
                  </span>
                </td>
                <!--end::Age-->

                <!--begin::Hash-->
                <td>
                  <span
                    class="text-muted text-hover-primary d-block mb-1 fs-7 cut"
                  >
                    {{ item.checksum }}
                  </span>
                </td>
                <!--end::Hash-->

                <!--begin::Type-->
                <td>
                  <span class="text-muted mb-1 fs-7">
                    {{
                      item.type ? item.type.toUpperCase() : "PROTECTION BLOCK"
                    }}
                  </span>
                </td>
                <!--end::Type-->

                <!--begin::Status-->
                <td>
                  <!--begin::In queue blocks-->
                  <span
                    class="badge badge-light-warning"
                    v-if="item.file_hash == null"
                    >In queue</span
                  >
                  <!--end::In queue blocks-->
                  <!--begin::Registered blocks-->
                  <span class="badge badge-light-success" v-else
                    >Registered</span
                  >
                  <!--end::Registered blocks-->
                </td>
                <!--end::Status-->
              </tr>
            </template>
            <tr class="table-light">
              <td colspan="3" class="bg-primary"></td>
              <td class="text-center">
                <router-link
                  :to="{ name: 'blocks' }"
                  class="btn btn-default btn-dark text-white"
                  translate=""
                  >See all blocks
                </router-link>
              </td>
              <td colspan="2" class="bg-primary"></td>
            </tr>
          </tbody>
          <!--end::Table body-->
        </table>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
      <div v-else>
        <div class="card bg-dark">
          <!--begin::Header-->
          <div class="card-header align-items-center border-0 mt-4">
            <h3 class="card-title fs-1 text-muted ps-5">
              Most Recent Blocks
            </h3>
          </div>
          <!--end::Header-->
          <!--start::Separator-->
          <div class="menu-item">
            <div class="menu-content">
              <div class="separator mx-1 my-1"></div>
            </div>
          </div>
          <!--end::Separator-->
          <!--begin::Body-->
          <div class="card-body pt-5">
            <!--begin::Text-->
            <div class="fw-mormal timeline-content text-muted ps-3">
              <p class="text-light fs-7">
                Something went wrong. Please refresh the page.
              </p>
            </div>
            <!--end::Text-->
            <!--start::Separator-->
            <div class="menu-item">
              <div class="menu-content">
                <div class="separator mx-1 my-4"></div>
              </div>
            </div>
            <!--end::Separator-->
            <!--begin::Info section-->
          </div>
          <!--end: Card Body-->
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Tables Widget 13-->
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "insights",
  props: {
    blocks: Object,
    widgetClasses: String
  },
  setup() {
    onMounted(() => {
      localStorage.setItem("currentPage", "1");
    });
  },
  beforeUnmount() {
    // this.blocks = null;
  },
  computed: {
    length() {
      return this.blocks?.length;
    }
  },
  methods: {
    /**
     * Formats number with leading hashtag and zeros
     * @returns string
     */
    pad(num, size?) {
      //fixed size
      const SIZE = size ? size : 9;
      // num = num.toString();
      num = `${num}`;

      while (num.length < SIZE) {
        num = "0" + num;
      }

      return "#" + num;
    },

    /**
     * Calculates the time since block's creation
     * @returns string
     */
    calcElapsedTime(time) {
      //if it's a protection block
      if (time == null) {
        return "";
      } else {
        // data string to milisec
        const createdTime = Number(new Date(time)); // UTC time
        const nowUtc = Number(new Date(this.getCurrentTimeUTC())); // UTC time
        const diff = Number(nowUtc - createdTime);

        return this.msToTime(diff);
      }
    },

    /**
     * Gets the UTC time
     * @returns string
     */
    getCurrentTimeUTC() {
      //RETURN:
      //      = number of milliseconds between current UTC time and midnight of January 1, 1970
      const tmLoc = new Date();
      //The offset is in minutes -- convert it to ms
      return tmLoc.getTime() + tmLoc.getTimezoneOffset() * 60000;
    },

    /**
     * Formats miliseconds to hh:mm:ss
     * @returns string
     */
    msToTime(s) {
      // Pad to 2 or 3 digits, default is 2
      function pad(n, z?) {
        z = z || 2;
        return ("00" + n).slice(-z);
      }

      const ms = s % 1000;
      s = (s - ms) / 1000;
      const secs = s % 60;
      s = (s - secs) / 60;
      const mins = s % 60;
      const hrs = (s - mins) / 60;

      // return pad(hrs) + ":" + pad(mins) + ":" + pad(secs) + "." + pad(ms, 3);
      if (hrs == 1) {
        return pad(hrs) + " hour ago";
      } else if (hrs != 0) {
        return pad(hrs) + " hours ago";
      } else {
        return pad(mins) + " minutes ago";
      }
    }
  }
});
</script>

<style scoped>
td .cut {
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
th,
tr,
td {
  text-align: center;
}
</style>
