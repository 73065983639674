import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import About from "@/views/pages/insights/AboutCw.vue";
import Insights from "@/views/pages/insights/Insights.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
export default defineComponent({
    name: "latest",
    components: {
        About: About,
        Insights: Insights
    },
    setup: function () {
        var store = useStore();
        store.commit("resetInsightsBlocks");
        store.dispatch("fetchInsights");
        onMounted(function () {
            setCurrentPageTitle("Most Recent Blocks");
        });
    },
    computed: {
        blocks: function () {
            var store = useStore();
            return store.getters.latestBlocks;
        },
        pendingBlocks: function () {
            var store = useStore();
            return store.getters.pendingBlocks;
        },
        status: function () {
            var store = useStore();
            return store.getters.isOnline;
        }
    }
});
