<template>
  <!--begin::Latest Blocks-->
  <!--begin::Latest Blocks Table-->
  <div class="row">
    <div class="col-xxl-8">
      <Insights widget-classes="mb-5 mb-xl-8" :blocks="blocks"></Insights>
    </div>
    <!--end::Latest Blocks Table-->
    <!--begin::About-->
    <div class="col-xxl-4">
      <About
        widget-classes="card-xxl-stretch my-5 mb-xl-10"
        :pendingBlocks="pendingBlocks"
        :status="status"
      ></About>
    </div>
    <!--end::About-->
  </div>
  <!--end::Latest Blocks-->
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import About from "@/views/pages/insights/AboutCw.vue";
import Insights from "@/views/pages/insights/Insights.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "latest",
  components: {
    About,
    Insights
  },
  setup() {
    const store = useStore();
    store.commit("resetInsightsBlocks");
    store.dispatch("fetchInsights");

    onMounted(() => {
      setCurrentPageTitle("Most Recent Blocks");
    });
  },
  computed: {
    blocks() {
      const store = useStore();
      return store.getters.latestBlocks;
    },
    pendingBlocks() {
      const store = useStore();
      return store.getters.pendingBlocks;
    },
    status() {
      const store = useStore();
      return store.getters.isOnline;
    }
  }
});
</script>
