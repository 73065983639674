<template>
  <!--begin::List Widget 5-->
  <div class="card bg-dark" :class="widgetClasses">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <img
        alt="cwChain Logo"
        src="@/assets/media/logos/cw_chain.png"
        class="h-60px logo"
      />
      <!--begin::Status Bar-->
      <div class="card-toolbar" v-if="status">
        <span class="badge badge-success text-dark">Online</span>
      </div>
      <div class="card-toolbar" v-else>
        <span class="badge badge-danger">Offline</span>
      </div>
      <!--end::Status Bar-->
    </div>
    <!--end::Header-->
    <!--start::Separator-->
    <div class="menu-item">
      <div class="menu-content">
        <div class="separator mx-1 my-1"></div>
      </div>
    </div>
    <!--end::Separator-->
    <!--begin::Body-->
    <div class="card-body pt-5">
      <!--begin::Text-->
      <div class="fw-mormal timeline-content text-muted ps-3">
        <h3 class="card-title align-items-start flex-column">
          <p class="fw-bolder mb-2 text-white">About chain sync</p>
          <p class="text-muted fw-bolder fs-6">How it really works</p>
        </h3>
        <p class="text-light fs-7">
          On our blockchain-based platform, every asset submitted for copyright
          takes one block which gets linked to its previous one. Therefore,
          every time a new block is added, all assets become more secure. The
          newly submitted blocks create a chain-link. Every 24 hours we take the
          chain-link created and we immutably append it to the end of our
          blockchain. We call that a chain sync.
        </p>
      </div>
      <!--end::Text-->
      <!--start::Separator-->
      <div class="menu-item">
        <div class="menu-content">
          <div class="separator mx-1 my-4"></div>
        </div>
      </div>
      <!--end::Separator-->
      <!--begin::Info section-->
      <div class="d-flex flex-wrap my-2">
        <!--begin::Due-->
        <div
          class="border border-light rounded-pill min-w-125px py-3 px-4 me-7 mb-3"
        >
          <div class="fs-6 text-gray-700 fw-bolder my-1 mx-3 mb-2">
            <span> Next Sync in {{ this.calcNextSync() }}</span>
          </div>
          <!--begin::Progess Bar-->
          <div class="progress mb-3" style="height: 17px;">
            <div
              class="progress-bar progress-bar-striped text-dark fw-bolder fs-3"
              role="progressbar"
              :style="{ width: `${elapsed}%` }"
              :aria-valuenow="`${elapsed}`"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{ Math.round(elapsed) + "%" }}
            </div>
          </div>
          <!--end::Progess Bar-->
          <!-- <div class="fw-bold text-gray-400">Next Sync</div> -->
        </div>
        <!--end::Due-->
        <!--begin::Budget-->
        <div
          class="border border-light rounded-pill min-w-125px py-3 px-4 mb-0 my-2"
        >
          <div class="fs-1 text-gray-400 fw-bolder text-center">
            {{ pendingBlocks }}
          </div>
          <div class="fs-6 fw-bold text-gray-700">Blocks in queue</div>
        </div>
        <!--end::Budget-->
      </div>
      <!--end::Info section-->
    </div>
    <!--end: Card Body-->
  </div>
  <!--end: List Widget 5-->
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";

export default defineComponent({
  name: "kt-widget-5",
  props: {
    pendingBlocks: Number,
    status: Boolean,
    widgetClasses: String
  },
  components: {},
  setup() {
    onMounted(() => {
      MenuComponent.reinitialization();
    });
  },
  computed: {
    elapsed() {
      const DAY = 86400000;

      // const utcMidnight = this.getUTCMidnight();
      // const currTime = this.getCurrentTimeUTC();

      // date in local timezone
      const dateNow = new Date();
      // UTC coming midnight
      dateNow.setUTCHours(24, 0, 0, 0);
      // UTC coming midnight to msec
      const midnight =
        dateNow.getTime() + dateNow.getTimezoneOffset() * 60 * 1000;
      const utcMidnight = Number(new Date(midnight));

      //      = number of milliseconds between current UTC time and midnight of January 1, 1970
      const tmLoc = new Date();
      //The offset is in minutes -- convert it to ms
      const currTime = tmLoc.getTime() + tmLoc.getTimezoneOffset() * 60000;

      const diff = utcMidnight - currTime;
      const progress = (diff / DAY) * 100;
      const progressPerCent = Number(
        (Math.round(progress * 100) / 100).toFixed(2)
      );
      const res = 100 - progressPerCent;

      return res;
    }
  },
  methods: {
    /**
     * Calculates remaining time for the chain sync
     * @returns string
     */
    calcNextSync() {
      const currTimeUTC = Number(new Date(this.getCurrentTimeUTC()));

      const midnightUTC = this.getUTCMidnight();

      const diff = Number(midnightUTC - currTimeUTC);

      return this.msToTime(diff);
    },

    /**
     * Gets the UTC midgnitime in msec
     * @returns number
     */
    getUTCMidnight() {
      // date in local timezone
      const dateNow = new Date();
      // UTC coming midnight
      dateNow.setUTCHours(24, 0, 0, 0);
      // UTC coming midnight to msec
      const midnight =
        dateNow.getTime() + dateNow.getTimezoneOffset() * 60 * 1000;
      return Number(new Date(midnight));
    },

    /**
     * Gets the UTC time
     * @returns string
     */
    getCurrentTimeUTC() {
      //RETURN:
      //      = number of milliseconds between current UTC time and midnight of January 1, 1970
      const tmLoc = new Date();
      //The offset is in minutes -- convert it to ms
      return tmLoc.getTime() + tmLoc.getTimezoneOffset() * 60000;
    },

    /**
     * Formats miliseconds to hh:mm:ss
     * @returns string
     */
    msToTime(s) {
      // Pad to 2 or 3 digits, default is 2
      function pad(n, z?) {
        z = z || 2;
        return ("00" + n).slice(-z);
      }

      const ms = s % 1000;
      s = (s - ms) / 1000;
      const secs = s % 60;
      s = (s - secs) / 60;
      const mins = s % 60;
      const hrs = (s - mins) / 60;

      return pad(hrs) + " hours and " + pad(mins) + " minutes";
    }
  }
});
</script>
